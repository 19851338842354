<template>
  <v-row>
    <v-col cols="12" v-if="$store.state.documents.retrieveLoader">
      <v-skeleton-template type="table-heading, list-item-two-line" />
      <v-skeleton-loader class="mt-5" transition="fade-transition" type="table" />
    </v-col>
    <v-col cols="12" v-else>
      <v-card>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="grey-500--text subtitle-1 font-weight-medium">
            <router-link :to="{name: 'CreditNoteRetrieve', params: {id: instance.credit_note.id}}" class="subtitle-1 routerLink font-weight-medium">{{instance.credit_note.document.name}} Nº {{instance.credit_note.number}}</router-link>
            <v-icon class="mx-1" color="grey-500">mdi-chevron-right</v-icon>
            <span class="subtitle-1 font-weight-medium">Nota de débito Nº {{instance.number}}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-1">
              <!-- <v-btn class="ml-1" @click="dialogReminder=true" outlined color="grey-500"><v-icon small left>mdi-email</v-icon>Enviar nota de crédito</v-btn> -->
              <v-btn class="ml-1" @click="renderDocument('documents', 'debitnotes')" outlined color="grey-500" retain-focus-on-click><v-icon small left>mdi-file-document</v-icon>PDF</v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <v-row class="mx-0 background">
            <v-col cols="2">
              <span class="d-block mb-1">Total</span>
              <span class="body-1 font-weight-medium mr-1">{{instance.total | currency(instance.credit_note.documents.currency)}}</span>
              <span>{{instance.credit_note.documents.currency}}</span>
            </v-col>
            <v-divider class="my-2" vertical />
            <v-col cols="3" class="ml-2">
              <span class="d-block mb-1 text-truncate" style="max-width: 200px;">{{instance.credit_note.documents.customer.name}}</span>
              <v-btn :to="{name: 'CreditNoteRetrieve', params: {id: instance.credit_note.id}}" text color="blue-500" height="20" retain-focus-on-click :ripple="false">{{instance.credit_note.document.name}} Nº {{instance.credit_note.number}}<v-icon right small>mdi-arrow-right</v-icon></v-btn>
            </v-col>
            <v-divider class="my-2" vertical />
            <v-col cols="2" class="ml-2">
              <span class="d-block mb-1">Fecha</span>
              <div class="body-1 font-weight-medium mt-1">{{instance.date | date}}</div>
            </v-col>
          </v-row>
          <v-divider />
          <!-- reminders -->
          <!-- <v-list>
            <template v-if="messagesList.length">
              <template v-for="(d, i) in messagesList">
                <v-list-item class="py-3" :key="i">
                  <v-list-item-icon><v-icon color="blue-500">mdi-email-send</v-icon></v-list-item-icon>
                  <v-list-item-title>
                    <span class="d-block body-2 grey-500--text font-weight-medium">La nota de crédito fue enviada a {{d.recipient[0]}} <template v-if="d.recipient.length > 1">y otros {{d.recipient.length - 1}} contactos</template> por {{d.user}}</span>
                    <span class="d-block">{{d.created | dateTime}}</span>
                  </v-list-item-title>
                </v-list-item>
                <v-divider :key="`divider-${i}`" />
              </template>
            </template>
          </v-list> -->
          <!-- end reminders -->
          <v-row>
            <v-col class="pb-0" cols="12">
              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-title class="grey-500--text subtitle-1 font-weight-medium">Información general</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="6">
              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-title>Nota de la anulación</v-list-item-title>
                  <v-list-item-subtitle>{{instance.note}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Creado por</v-list-item-title>
                  <v-list-item-subtitle>{{instance.user}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Creado el</v-list-item-title>
                  <v-list-item-subtitle>{{instance.created | dateTime}}</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="6">
              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-title>Nota interna</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle v-if="instance.internal_note">{{instance.internal_note}}</v-list-item-subtitle>
                  <v-list-item-subtitle class="font-italic text--disabled" v-else>Sin nota interna</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- tax agency -->
      <v-card class="px-0 my-5" v-if="account.country.id == 'CL' && instance.document.has_local_format">
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="grey-500--text subtitle-1 font-weight-medium">Gestión agencia fiscal</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-1">
              <v-menu left :close-on-content-click="false" transition="scale-transition" offset-y min-width="160px">
                <template v-slot:activator="{on, attrs}">
                  <v-btn v-bind="attrs" v-on="on" outlined retain-focus-on-click>
                    <v-icon left>mdi-plus-box </v-icon>Opciones<v-icon size="18" right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-btn :href="instance.local_file" target="_blank" text height="30" width="160" retain-focus-on-click :ripple="false">Descargar XML</v-btn>
              </v-menu>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-0">
          <v-row>
            <v-col cols="8">
              <v-list class="v-list-form transparent">
                <v-list-item>
                  <v-list-item-title>Estado</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-btn v-if="!instance.tax_agency.cl_status" @click="taxAgencyService('documents', 'debitnotes', 'tax_agency_status')" :loading="taxAgencyLoader" text color="blue-500" :ripple="false">Actualizar estado</v-btn>
                    <v-chip v-else-if="instance.tax_agency.cl_status.includes('Rechazado')" class="caption" color="defaultGrey" text-color="grey-500" small>{{instance.tax_agency.cl_status}}<v-icon class="ml-1" x-small>mdi-close</v-icon></v-chip>
                    <v-chip v-else color="lightBlue" text-color="blue-500" small>{{instance.tax_agency.cl_status}}<v-icon class="ml-1" x-small>mdi-check</v-icon></v-chip>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item v-if="instance.tax_agency.cl_status_detail">
                  <v-list-item-title>Detalle del estado</v-list-item-title>
                  <v-list-item-subtitle>{{instance.tax_agency.cl_status_detail}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    Track ID
                    <v-tooltip v-if="!instance.tax_agency.cl_id" color="white-0" right transition="scale-transition">
                      <template v-slot:activator="{on}">
                        <v-icon v-on="on" small color="red">mdi-alert</v-icon>
                      </template>
                      <span class="d-block px-3 py-2">Es requerido enviar a la agencia fiscal</span>
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-left">
                    <span v-if="instance.tax_agency.cl_id">{{instance.tax_agency.cl_id}}</span>
                    <span v-else><v-btn @click="taxAgencyService('documents', 'debitnotes', 'tax_agency_send')" :loading="taxAgencyLoader" text color="blue-500" :ripple="false">Enviar a la agencia fiscal</v-btn></span>
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- end tax agency -->
    </v-col>

    <!-- dialog pdf -->
    <v-dialog v-model="modalRender" v-if="instance.id" width="700" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card>
        <v-toolbar flat height="55">
          <v-toolbar-title class="subtitle-1 grey-500--text font-weight-medium">PDF Nota de débito Nº {{instance.number}}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-1">
              <v-btn @click="modalRender=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <v-row v-if="loaderRender">
            <v-col cols="12" class="py-0 ma-0">
              <v-skeleton-loader class="rounded-0" transition="fade-transition" type="table" />
            </v-col>
          </v-row>
          <v-row v-else-if="!loaderRender && !renderUrl">
            <v-col cols="12" class="py-16 text-center">
              <v-icon class="d-block" color="grey-500" size="70">mdi-image-broken-variant</v-icon>
              <span class="d-block subtitle-1">PDF no disponible</span>
            </v-col>
          </v-row>
          <div id="render"></div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn :href="renderUrl" :download="`Debit_Note_N${instance.number}`" color="blue-500">Descargar PDF</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog pdf -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import RenderViewMixin from '@/mixins/RenderViewMixin'
import TaxAgencyViewMixin from '@/mixins/TaxAgencyViewMixin'
export default {
  inject: ['theme'],
  mixins: [
    GenericViewMixin,
    RenderViewMixin,
    TaxAgencyViewMixin
  ],
  components: {
    VSkeletonTemplate: {
      functional: true,
      render (h, { data, props, children }) {
        return h('v-skeleton-loader', {
          ...data,
          props: {
            boilerplate: true,
            elevation: 2,
            ...props
          }
        }, children)
      }
    }
  },
  created () {
    this.retrieve()
  },
  computed: mapState({
    instance: state => state.documents.debitnotesDetail
  }),
  methods: {
    retrieve () {
      this.$store.dispatch('documents/RETRIEVE', {
        resource: 'debitnotes',
        id: this.$route.params.id,
        query: {
          expand: 'credit_note.documents,credit_note.document,document'
        }
      })
    }
  }
}
</script>